import { debounce } from 'lodash'
import { axiosQuotes } from '../../utils/apiClient'

// Couldn't figure out how to prevent NewQuoteStepsContainer from calling this multiple times.
// So gave up and added a debounce.
const reqGetQuoteRates = debounce(
	async (params) => {
		const [error, data] = await axiosQuotes
			.post(`/rate${params}`, {})
			.then((response) => [null, response])
			.catch((err) => [err, err])

		return [error, data]
	},
	1000,
	{ leading: true, trailing: false },
)

export const reqSelectRate = async (params, insurancePayload) => {
	const [error, data] = await axiosQuotes
		.patch(`/rate${params}`, insurancePayload)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqSendByMail = async (id, body) => {
	const [error, data] = await axiosQuotes
		.post(`/rate/${id}/email`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export const reqDownloadRate = async (id) => {
	const [error, data] = await axiosQuotes
		.get(`/rate/${id}/pdf`, { responseType: 'blob' })
		.then((response) => [null, response])
		.catch((err) => [err, err])

	return [error, data]
}

export default reqGetQuoteRates
