import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Radio, Typography, RadioGroup, FormControl, FormControlLabel, Grid } from '@material-ui/core'
import './AddRemoveInsurance.scss'
import { DEFAULT_ADMIN_FEE } from '../../../../utils/constants'
import { displayDollarValue, insuranceSettingsAreValid } from '../../../../utils/helpers'
import ConfirmInsurance from '../ConfirmInsurance'

const AddRemoveInsurance = ({
	customerRate,
	typeAmount,
	typeAmountValue,
	selectedRateId,
	rateId,
	onNextStepThree,
	maxReplacementValue,
	setInsuranceCost,
	setInsuranceValue,
	openConfirmInsurance,
	setOpenConfirmInsurance,
	enableInsuranceOptIn,
	defaultDeclaredValue,
	declaredFreightValue,
	setDeclaredFreightValue,
	withInsurance,
	setWithInsurance,
}) => {
	const [insuranceSelected, setInsuranceSelected] = useState(withInsurance)

	const insuranceCost = useMemo(
		() => Math.max(15, Number((declaredFreightValue * 0.0035).toFixed(2))),
		[declaredFreightValue],
	)

	// When declared freight value changes, we'll use these variables to calculate insurance uplift
	const { computedInsuranceType, computedInsuranceValue } = useMemo(() => {
		let tempInsuranceType = typeAmount
		let tempInsuranceValue = typeAmountValue
		if (
			!insuranceSettingsAreValid({
				typeAmount,
				typeAmountValue,
			})
		) {
			// Fallback to default settings when the given settings are invalid
			tempInsuranceType = 'ADMIN_FEE'
			tempInsuranceValue = DEFAULT_ADMIN_FEE
		}

		switch (tempInsuranceType) {
			case 'MARGIN':
				return {
					computedInsuranceType: 'MARGIN',
					computedInsuranceValue: Number(tempInsuranceValue),
				}
			case 'FLAT':
				return {
					computedInsuranceType: 'FLAT',
					computedInsuranceValue: Number(tempInsuranceValue),
				}
			default:
				// As of FPV-733, "ADMIN_FEE" default insurance tiers is the default insurance uplift
				return {
					computedInsuranceType: tempInsuranceType,
					computedInsuranceValue: tempInsuranceValue,
				}
		}
	}, [typeAmount, typeAmountValue])

	const insuranceCostPlusUplift = useMemo(() => {
		if (declaredFreightValue > 0) {
			let tempInsuranceType = computedInsuranceType
			let tempInsuranceValue = computedInsuranceValue

			if (computedInsuranceType === 'MARGIN' || computedInsuranceType === 'FLAT') {
				// The insurance type is 'MARGIN' or 'FLAT'
				// So we use the number as-is after coercing it to a number
				tempInsuranceValue = Number(computedInsuranceValue)
			} else {
				// Else the insurance type is 'ADMIN_FEE' so we need to
				// Locate the admin fee tier applicable to the insurance cost
				const applicableTier = JSON.parse(computedInsuranceValue).find(
					(tier) => insuranceCost >= tier.min && insuranceCost <= tier.max,
				)

				if (applicableTier) {
					// Use the insurance type and value defined on the applicable tier
					tempInsuranceType = applicableTier.type
					tempInsuranceValue = applicableTier.amount
				} else {
					// Fallback to the most expensive tier if an applicable tier was not found
					// Ideally, this should never occur. It may occur temporarily if the user
					// enters a replacement value above the current maximum
					// In which case this value would be updated once they enter a valid value
					// And should fall into an applicable tier assuming the tiered definitions are robust
					tempInsuranceType = 'FLAT'
					tempInsuranceValue = 15
				}
			}

			switch (tempInsuranceType) {
				case 'FLAT':
					return insuranceCost + tempInsuranceValue
				default:
					// Default insurance type is 'MARGIN'
					return insuranceCost + insuranceCost * (tempInsuranceValue / 100)
			}
		} else {
			// Insurance cost is $0 if declared value is $0
			return 0
		}
	}, [insuranceCost, declaredFreightValue, computedInsuranceType, computedInsuranceValue])

	const customerRateWithInsurance = useMemo(
		() => insuranceCostPlusUplift + customerRate,
		[insuranceCostPlusUplift, customerRate],
	)

	useEffect(() => {
		if (selectedRateId === rateId) {
			// Only for the rate which was selected by the user:
			// Update the insurance state to be used for the payload to the quotes service
			setWithInsurance(insuranceSelected)
			setInsuranceCost(insuranceSelected ? insuranceCost : 0)
			setInsuranceValue(insuranceSelected ? insuranceCostPlusUplift : 0)
		}
	}, [
		rateId,
		selectedRateId,
		insuranceSelected,
		setWithInsurance,
		insuranceCost,
		setInsuranceCost,
		insuranceCostPlusUplift,
		setInsuranceValue,
	])

	return (
		<>
			<FormControl className="add-remove-insurance-container">
				<RadioGroup
					value={insuranceSelected}
					onChange={(e) => {
						setInsuranceSelected(e.currentTarget.value === 'true')
					}}
				>
					<FormControlLabel
						value
						control={<Radio color="secondary" />}
						label={
							<Grid container direction="row">
								<Grid item>{displayDollarValue(customerRateWithInsurance)}</Grid>
								<Grid
									item
									style={{
										marginTop: -7,
									}}
								>
									<Typography variant="caption" color="secondary">
										With Insurance
									</Typography>
								</Grid>
							</Grid>
						}
					/>
					<FormControlLabel
						value={false}
						control={<Radio color="secondary" />}
						label={displayDollarValue(customerRate)}
					/>
				</RadioGroup>
			</FormControl>
			<ConfirmInsurance
				applicableRate={selectedRateId === rateId}
				withInsurance={withInsurance}
				defaultDeclaredValue={defaultDeclaredValue}
				declaredFreightValue={declaredFreightValue}
				setDeclaredFreightValue={setDeclaredFreightValue}
				openConfirmInsurance={openConfirmInsurance}
				setOpenConfirmInsurance={setOpenConfirmInsurance}
				enableInsuranceOptIn={enableInsuranceOptIn}
				maxReplacementValue={maxReplacementValue}
				onNextStepThree={onNextStepThree}
				customerRateWithInsurance={customerRateWithInsurance}
			/>
		</>
	)
}

AddRemoveInsurance.propTypes = {
	rateId: PropTypes.number.isRequired,
	customerRate: PropTypes.number.isRequired,
	defaultDeclaredValue: PropTypes.number.isRequired,
	declaredFreightValue: PropTypes.number.isRequired,
	setDeclaredFreightValue: PropTypes.func.isRequired,
	withInsurance: PropTypes.bool.isRequired,
	setWithInsurance: PropTypes.func.isRequired,
	setInsuranceCost: PropTypes.func.isRequired,
	setInsuranceValue: PropTypes.func.isRequired,
	typeAmount: PropTypes.string.isRequired,
	typeAmountValue: PropTypes.string.isRequired,
	selectedRateId: PropTypes.number.isRequired,
	maxReplacementValue: PropTypes.number.isRequired,
	openConfirmInsurance: PropTypes.bool.isRequired,
	setOpenConfirmInsurance: PropTypes.func.isRequired,
	enableInsuranceOptIn: PropTypes.bool.isRequired,
	onNextStepThree: PropTypes.func.isRequired,
}

export default AddRemoveInsurance
