import React, {
	useEffect,
	useState,
	useCallback,
	useContext,
	useRef,
	forwardRef,
	useImperativeHandle,
	useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import moment from 'moment'
import { Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { reqGetSettingByName } from 'redux/requests/App.request'
import sweetAlertModal from '../../../../components/SweetAlertModal'
import DownloadRates from '../../components/DownloadRates'
import PingBlue from '../../../../assets/svg/pblue.svg'
import PingBlueOrigin from '../../../../assets/svg/p2blue.svg'
import {
	quotesListRoute,
	createQuotesRoute,
	expiredError,
	convertedShipmentError,
	createShipmentsRoute,
	permissionName,
} from '../../../../utils/constants'
import {
	setTabCurrentRoute,
	setTabTitle,
	setTabOrigin,
	setTabDestination,
	setTabOriginAccessorials,
	setTabDestinationAccessorials,
	setTabShipmentItems,
	setTabDefaultShipmentItems,
	setTabReferences,
	setTabStep,
	setTabDestinationData,
	setTabOriginData,
	setTabQuoteId,
	setTabRates,
	setTabStatus,
	setTabIsNewQuote,
} from '../../../../redux/actions/MultiTabs.actions'
import isPermit from '../../../../utils/permission'
import SessionContext from '../../../../context/session'
import './NewQuoteStepsContainer.scss'
import {
	setCreateQuoteInfo,
	createQuote,
	editQuote,
	validateQuoteExpiration,
} from '../../../../redux/actions/Quotes.actions'
import {
	clearQuoteDetailsForReQuote,
	fetchQuoteDetails,
	clearDetailsCopyForQuote,
} from '../../../../redux/actions/QuoteDetails.actions'
import { fetchQuoteRates, clearRates, selectRate } from '../../../../redux/actions/Rates.actions'
import { fetchDefaultItems } from '../../../../redux/actions/TemplateItems.actions'

import NewQuoteShipmentStepOne from '../../components/NewQuoteShipmentStepOne'
import NewQuoteShipmentStepTwo from '../../components/NewQuoteShipmentStepTwo'
import useStyles from './styles'
import actionUpdateDeliveryDay from './functions/actionUpdateDeliveryDay'

const markersData = [
	{
		coordinate: {
			lat: 34.05223,
			lng: -118.24368,
		},
		content: {
			type: 'Origin',
			city: 'Los Angeles',
		},
		icon: PingBlueOrigin,
	},
	{
		coordinate: {
			lat: 41.85003,
			lng: -87.65005,
		},
		content: {
			type: 'Destination',
			city: 'Chicago',
		},
		icon: PingBlue,
	},
]

const NewQuoteStepsContainer = forwardRef(({ activeStep, setActiveStep1, setActiveStep2 }, refGlobal) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const matches = useMediaQuery('(min-width:1024px)')
	const history = useHistory()
	const location = useLocation()
	const currentTabs = useSelector((state) => state.multiTabs.tabs)
	const reQuoteDetails = useSelector((state) => state.quoteDetails.quoteDetailsReQuote)
	const quoteDetailsState = useSelector((state) => state.quoteDetails)
	const quoteRates = useSelector((state) => state.quoteRates)
	const [translator] = useTranslation()
	const [isReQuote, setIsReQuote] = useState(Object.keys(reQuoteDetails).length !== 0)
	const [hereMapsResponseOrigin, setHereMapsResponseOrigin] = useState([])
	const [hereMapsResponseDestination, setHereMapsResponseDestination] = useState([])
	const [valueOrigin, setValueOrigin] = useState({ label: '' })
	const [inputValueOrigin, setInputValueOrigin] = useState('')
	const [valueDestination, setValueDestination] = useState({ label: '' })
	const [inputValueDestination, setInputValueDestination] = useState('')
	const [originData, setOriginData] = useState({})
	const [destinationData, setDestinationData] = useState({})
	const [pickUpDate, setPickUpDate] = useState(new Date())
	const [originAccessorialsSelected, setOriginAccessorialsSelected] = useState([])
	const [valueOriginAccs, setValueOriginAccs] = useState([])
	const [destinationAccessorialsSelected, setDestinationAccessorialsSelected] = useState([])
	const [valueDestinationAccs, setValueDestinationAccs] = useState([])
	const [overlengthValue, setOverlengthValue] = useState(0)
	const [CODValue, setCODValue] = useState(0)
	const [shipmentItems, setShipmentItems] = useState([])
	const [previousItems, setPreviousItems] = useState([])
	const [previousReferences, setPreviousReferences] = useState([])
	const [referencesAdded, setReferencesAdded] = useState([])
	const [markers, setMarkers] = useState(markersData)
	const [totalWeight, setTotalWeight] = useState(0)
	const [accessorialsOriginName, setAccessorialsOriginName] = useState([])
	const [accessorialsDestinationName, setAccessorialsDestinationName] = useState([])
	const [isEdit, setIsEdit] = useState(false)
	const [creationDate, setCreationDate] = useState(new Date())
	const [siteTypeOrigin, setSiteTypeOrigin] = useState('business')
	const [siteTypeDestination, setSiteTypeDestination] = useState('business')
	const [linearFeet, setLinearFeet] = useState('')
	const { getUserData } = useContext(SessionContext)
	const { permissions, actualCompany } = getUserData()

	const [quoteCreatedId, setQuoteCreatedId] = useState(null)
	const [selectedRateId, setSelectedRateId] = useState(0)

	// Insurance values used in cost calculations, and the insurance payload used to book the rate
	const defaultDeclaredValue = 5000
	const [declaredFreightValue, setDeclaredFreightValue] = useState(defaultDeclaredValue)
	const [withInsurance, setWithInsurance] = useState(true)
	const [enableInsuranceOptIn, setEnableInsuranceOptIn] = useState(true)
	const [insuranceCost, setInsuranceCost] = useState(0)
	const [insuranceValue, setInsuranceValue] = useState(0)
	const [openConfirmInsurance, setOpenConfirmInsurance] = useState(false)
	const insurancePayload = useMemo(
		() => ({
			declaredFreightValue,
			withInsurance,
			insuranceCost,
			insuranceValue,
		}),
		[declaredFreightValue, withInsurance, insuranceCost, insuranceValue],
	)

	const [openQuoteSavedConfirm, setQuoteSavedConfirm] = useState(false)
	const [isCancellingQuote, setIsCancellingQuote] = useState(false)
	const [disableNextButton, setDisableNextButton] = useState(false)

	const isNewQuoteState = location?.state?.isNewQuote ?? false

	const itemInputsRef = useRef([])
	const itemsRef = useRef(shipmentItems)

	const referenceInputsRef = useRef([])
	const referencesRef = useRef(referencesAdded)

	const freightClassRef = useRef(0)
	const infoItemsValues = useRef()

	useEffect(() => {
		const config = {
			headers: { 'X-Company-id': Number(actualCompany) },
		}
		reqGetSettingByName('ENABLE_INSURANCE_OPT_IN', config).then(([error, data]) => {
			if (error) return
			const enableInsuranceOptInSetting = data?.value.toLowerCase() === 'true'
			setWithInsurance(enableInsuranceOptInSetting)
			setEnableInsuranceOptIn(enableInsuranceOptInSetting)
		})
	}, [])

	useEffect(() => {
		itemsRef.current = shipmentItems
	}, [shipmentItems])

	useEffect(() => {
		referencesRef.current = referencesAdded
	}, [referencesAdded])

	/*
    Socket implementation was commented temporary due to unnecessary calls to the server for now.
    Commented on 28-04-2021.

    In 22-02-2022 it was updated to go from socket to pusher service.
  */

	// const ratesSocket = useContext(SocketContext);
	// const handleRatesAdded = useCallback((rates) => {
	//   console.log('Rates added by socket', rates);
	// }, []);

	// useEffect(() => {
	//   const quoteId = Number(quoteCreatedId);
	//   ratesSocket.subscribe('Notifications');
	//   ratesSocket.bind(quoteId, handleRatesAdded);

	//   return (() => { ratesSocket.unbind('Notifications'); });
	// }, [quoteCreatedId, ratesSocket, handleRatesAdded]);

	useEffect(() => {
		dispatch(setTabCurrentRoute(createQuotesRoute))
		dispatch(setTabTitle('New Quote'))
		dispatch(setTabStatus('PENDING'))
		dispatch(clearRates())
	}, [dispatch, translator])

	useEffect(() => {
		// Requote process by requote action in the saved quotes list
		// or by accepting shiplify suggestions in the create shipment view.
		if (Object.keys(reQuoteDetails).length !== 0) {
			setQuoteCreatedId(Number(reQuoteDetails.id))
			const originValue = `${reQuoteDetails.zipcode_origin}, ${reQuoteDetails.city_origin}, ${reQuoteDetails.state_origin}, ${reQuoteDetails.country_origin}`
			const destinationValue = `${reQuoteDetails.zipcode_destination}, ${reQuoteDetails.city_destination}, ${reQuoteDetails.state_destination}, ${reQuoteDetails.country_destination}`
			const reQuoteOriginData = {
				address: reQuoteDetails.address_origin,
				address2: reQuoteDetails.address2_origin,
				contact: reQuoteDetails.contact_origin,
				email: reQuoteDetails.email_origin,
				id: reQuoteDetails.location_origin_id,
				latitude: reQuoteDetails.lat_origin,
				longitude: reQuoteDetails.lon_origin,
				name: reQuoteDetails.contact_origin,
				phone: reQuoteDetails.phone_origin,
				postalCode: reQuoteDetails.zipcode_origin,
				country: reQuoteDetails.country_origin,
				state: reQuoteDetails.state_origin,
				city: reQuoteDetails.city_origin,
				label: originValue,
			}
			const reQuoteDestinationData = {
				address: reQuoteDetails.address_destination,
				address2: reQuoteDetails.address2_destination,
				contact: reQuoteDetails.contact_destination,
				email: reQuoteDetails.email_destination,
				id: reQuoteDetails.location_destination_id,
				latitude: reQuoteDetails.lat_destination,
				longitude: reQuoteDetails.lon_destination,
				name: reQuoteDetails.contact_destination,
				phone: reQuoteDetails.phone_destination,
				postalCode: reQuoteDetails.zipcode_destination,
				country: reQuoteDetails.country_destination,
				state: reQuoteDetails.state_destination,
				city: reQuoteDetails.city_destination,
				label: destinationValue,
			}
			setOriginData(reQuoteOriginData)
			setDestinationData(reQuoteDestinationData)
			const newMarkers = [
				{
					coordinate: {
						lat: reQuoteDetails.lat_origin,
						lng: reQuoteDetails.lon_origin,
					},
					content: {
						type: 'Origin',
						city: reQuoteDetails.city_origin,
					},
					icon: PingBlueOrigin,
				},
				{
					coordinate: {
						lat: reQuoteDetails.lat_destination,
						lng: reQuoteDetails.lon_destination,
					},
					content: {
						type: 'Destination',
						city: reQuoteDetails.city_destination,
					},
					icon: PingBlue,
				},
			]
			setMarkers(newMarkers)
			const originAccesorials = []
			const destinationAccesorials = []
			reQuoteDetails.accesorials.forEach((accessorial) => {
				if (accessorial.origin_destination === 'ORIGIN') {
					const accessorialObj = {
						accessorialId: accessorial.accessorial_id || accessorial.accessorialId,
						name: accessorial.name,
						value: accessorial.value,
						internalCode: accessorial.internal_code || accessorial.internalCode,
						origin_destination: accessorial.origin_destination,
					}
					originAccesorials.push(accessorialObj)
				} else if (accessorial.origin_destination === 'DESTINATION') {
					const accessorialObj = {
						accessorialId: accessorial.accessorial_id || accessorial.accessorialId,
						name: accessorial.name,
						value: accessorial.value,
						internalCode: accessorial.internal_code || accessorial.internalCode,
						origin_destination: accessorial.origin_destination,
					}
					destinationAccesorials.push(accessorialObj)
				}
			})
			const shipItems = reQuoteDetails.items.map((item) => ({
				commodity: item.description,
				freightClass: item.class,
				hazardous: item.is_haz_mat,
				height: item.dimensions_height,
				length: item.dimensions_lenght,
				nmfc: item.nmfc ? `${item.nmfc}${item.sub_nmfc ? `-${item.sub_nmfc}` : ''}` : null,
				packageType: item.unit_type,
				quantity: item.pieces_value,
				stackable: item.stackable,
				width: item.dimensions_width,
				weight: item.weight_value,
				hazmatData: item.is_haz_mat
					? {
							shippingName: item.haz_mat_shipping_name,
							hazMatNumberType: item.haz_mat_number_type,
							hazMatNumber: item.haz_mat_number,
							hazMatClass: item.haz_mat_class,
							hazMatGroup: item.haz_mat_group,
							name: item.contact_info_name,
							phone: item.contact_info_phone,
						}
					: {},
			}))
			const references = reQuoteDetails.references.map((reference) => ({
				referenceType: reference.type,
				value: reference.value,
			}))
			setValueOrigin({ label: reQuoteOriginData.label })
			setValueDestination({ label: reQuoteDestinationData.label })
			delete reQuoteOriginData.label
			delete reQuoteDestinationData.label
			const data = {
				items: shipItems,
				origin: reQuoteOriginData,
				destination: reQuoteDestinationData,
				originAccessorial: originAccesorials,
				destinationAccessorial: destinationAccesorials,
				references,
				pickUpDate: moment(reQuoteDetails.est_pickup_date).format('L'),
			}
			const originAccs = originAccesorials.map((accsObj) => accsObj.name)
			const destinationAccs = destinationAccesorials.map((accsObj) => accsObj.name)
			const weight = reQuoteDetails.items.reduce((accumulator, item) => accumulator + item.weight_value, 0)
			dispatch(setCreateQuoteInfo(data))
			setInputValueOrigin(originValue)
			setInputValueDestination(destinationValue)
			setShipmentItems(shipItems)
			setPreviousItems(shipItems)
			setTotalWeight(weight)
			setValueOriginAccs(originAccesorials)
			setValueDestinationAccs(destinationAccesorials)
			setAccessorialsOriginName(originAccs)
			setAccessorialsDestinationName(destinationAccs)
			const changeSetReferences = reQuoteDetails.references.map((ref) => {
				if (ref?.id) {
					return {
						referenceType: ref?.type ?? '',
						value: ref?.value ?? '',
					}
				}
				return ref
			})
			setPreviousReferences(changeSetReferences)
			setReferencesAdded(changeSetReferences)
			if (reQuoteDetails?.linear_feet) {
				setLinearFeet(`${reQuoteDetails?.linear_feet}`)
			}
			const dateCreation = new Date(reQuoteDetails.audit_creation_date)
			setCreationDate(dateCreation)
			;(async () => {
				dispatch(setTabStep(2))
				setActiveStep2()
				setIsReQuote(true)
				const { statusRates } = await dispatch(fetchQuoteRates(`/${reQuoteDetails.id}`))
				if (statusRates === 'error') {
					sweetAlertModal('error', 'There was an error in the get rates process!', null, 'Ok', true, false)
				}
			})()
		}
		return () => {
			dispatch(clearQuoteDetailsForReQuote(''))
		}
	}, [reQuoteDetails, dispatch, setActiveStep2])

	const setMarkersData = () => {
		const newMarkers = [
			{
				coordinate: {
					lat: originData.latitude,
					lng: originData.longitude,
				},
				content: {
					type: 'Origin',
					city: originData.city,
				},
				icon: PingBlueOrigin,
			},
			{
				coordinate: {
					lat: destinationData.latitude,
					lng: destinationData.longitude,
				},
				content: {
					type: 'Destination',
					city: destinationData.city,
				},
				icon: PingBlue,
			},
		]
		setMarkers(newMarkers)
	}

	const loadDataFromPreviousQuote = (quoteDetails) => {
		const copyOriginData = {
			address: quoteDetails.address_origin,
			address2: quoteDetails.address2_origin,
			contact: quoteDetails.contact_origin,
			city: quoteDetails.city_origin,
			email: quoteDetails.email_origin,
			id: quoteDetails.location_origin_id,
			latitude: quoteDetails.lat_origin,
			longitude: quoteDetails.lon_origin,
			name: quoteDetails.company_origin,
			phone: quoteDetails.phone_origin,
			postalCode: quoteDetails.zipcode_origin,
			country: quoteDetails.country_origin,
			state: quoteDetails.state_origin,
			label: `${quoteDetails.zipcode_origin}, ${quoteDetails.city_origin}, ${quoteDetails.state_origin}, United States`,
		}

		const copyDestinationData = {
			address: quoteDetails.address_destination,
			address2: quoteDetails.address2_destination,
			contact: quoteDetails.contact_destination,
			city: quoteDetails.city_destination,
			email: quoteDetails.email_destination,
			id: quoteDetails.location_destination_id,
			latitude: quoteDetails.lat_destination,
			longitude: quoteDetails.lon_destination,
			name: quoteDetails.company_destination,
			phone: quoteDetails.phone_destination,
			postalCode: quoteDetails.zipcode_destination,
			country: quoteDetails.country_destination,
			state: quoteDetails.state_destination,
			label: `${quoteDetails.zipcode_destination}, ${quoteDetails.city_destination}, ${quoteDetails.state_destination}, United States`,
		}

		setOriginData(copyOriginData)
		setInputValueOrigin(copyOriginData.label)
		setInputValueDestination(copyDestinationData.label)
		setDestinationData(copyDestinationData)
		setValueOrigin(copyOriginData)
		setValueDestination(copyDestinationData)
		setSiteTypeOrigin('business')
		const copyItems = quoteDetails.items.map((item) => ({
			commodity: item.description,
			freightClass: item.class,
			hazardous: item.is_haz_mat,
			height: item.dimensions_height,
			length: item.dimensions_lenght,
			nmfc: item.nmfc ? `${item.nmfc}-${item.sub_nmfc}` : item.nmfc,
			packageType: item.unit_type,
			quantity: item.pieces_value,
			stackable: item.stackable,
			width: item.dimensions_width,
			weight: item.weight_value,
			hazmatData: item.is_haz_mat
				? {
						shippingName: item.haz_mat_shipping_name,
						hazMatNumberType: item.haz_mat_number_type,
						hazMatNumber: item.haz_mat_number,
						hazMatClass: item.haz_mat_class,
						hazMatGroup: item.haz_mat_group,
						name: item.contact_info_name,
						phone: item.contact_info_phone,
					}
				: {},
		}))
		setShipmentItems(copyItems)
		setPreviousItems(copyItems)
		const changeSetReferences = quoteDetails.references.map((ref) => {
			if (ref?.id) {
				return {
					referenceType: ref?.type ?? '',
					value: ref?.value ?? '',
				}
			}
			return ref
		})
		setPreviousReferences(changeSetReferences)
		setReferencesAdded(changeSetReferences)
		const originAccessls = []
		const destinationAccessls = []
		quoteDetails.accesorials.forEach((accessorial) => {
			if (accessorial.origin_destination === 'ORIGIN') {
				const accessorialObj = {
					accessorialId: accessorial.accessorial_id,
					internalCode: accessorial.internal_code,
					name: accessorial.name,
					origin_destination: 'ORIGIN',
					value: accessorial.value,
				}
				originAccessls.push(accessorialObj)
			} else if (accessorial.origin_destination === 'DESTINATION') {
				const accessorialObj = {
					accessorialId: accessorial.accessorial_id,
					internalCode: accessorial.internal_code,
					name: accessorial.name,
					origin_destination: 'DESTINATION',
					value: accessorial.value,
				}
				destinationAccessls.push(accessorialObj)
			}
		})
		setValueOriginAccs(originAccessls)
		setValueDestinationAccs(destinationAccessls)
		if (quoteDetails?.linear_feet) {
			setLinearFeet(`${quoteDetails?.linear_feet}`)
		}
	}

	useEffect(() => {
		const isCopyQuote = quoteDetailsState?.quoteDetailsCopy ?? {}
		if (quoteDetailsState && Object.keys(isCopyQuote).length !== 0) {
			loadDataFromPreviousQuote(isCopyQuote)
		}
		return () => {
			if (Object.keys(isCopyQuote).length !== 0) {
				dispatch(clearDetailsCopyForQuote())
			}
		}
	}, [quoteDetailsState, dispatch])

	useEffect(() => {
		const activeTab = currentTabs.find((tab) => tab.active === true)
		if (activeTab?.isNewQuote === undefined || isNewQuoteState) {
			dispatch(setTabIsNewQuote(true))
		}
	}, [currentTabs, dispatch, isNewQuoteState])

	useEffect(
		() => () => {
			const isCopyQuote = quoteDetailsState?.quoteDetailsCopy ?? {}
			if (Object.keys(isCopyQuote).length !== 0) {
				dispatch(clearDetailsCopyForQuote())
			}
			if (Object.keys(reQuoteDetails).length !== 0) {
				// clear requoteDetails
				dispatch(clearQuoteDetailsForReQuote(''))
			}
		},
		[],
	)

	const DefaultItemsProvider = () => {
		const callback = useCallback(async () => {
			const activeTab = currentTabs.find((tab) => tab.active === true)
			const isNewQuote = activeTab?.isNewQuote ?? true
			if ((activeStep !== 1 || !isNewQuote) && !isNewQuoteState) return
			const { status, resp } = await dispatch(fetchDefaultItems())
			if (status === 'error')
				sweetAlertModal('error', 'There was an error fetching default items', null, 'Ok', true, false)
			const items = resp?.items ?? []
			const defaultItemsSelected = items.map((item) => ({
				commodity: item.description,
				freightClass: item.class,
				hazardous: item.is_haz_mat,
				height: item.dimensions_height,
				length: item.dimensions_lenght,
				nmfc: item.nmfc ? [item.nmfc, item.sub_nmfc].join('-') : item.nmfc,
				packageType: item.unit_type,
				quantity: item.pieces_value,
				stackable: item.stackable,
				width: item.dimensions_width,
				weight: item.weight_value,
				hazmatData: item.is_haz_mat
					? {
							shippingName: item.haz_mat_shipping_name,
							hazMatNumberType: item.haz_mat_number_type,
							hazMatNumber: item.haz_mat_number,
							hazMatClass: item.haz_mat_class,
							hazMatGroup: item.haz_mat_group,
							name: item.contact_info_name,
							phone: item.contact_info_phone,
						}
					: {},
			}))
			if (Object.keys(reQuoteDetails).length === 0) {
				setShipmentItems(defaultItemsSelected)
				setPreviousItems(defaultItemsSelected)
			}
			dispatch(setTabDefaultShipmentItems(defaultItemsSelected))
		}, [])

		return { callback }
	}

	const { callback } = DefaultItemsProvider()

	useEffect(() => {
		callback()
	}, [callback])

	const getFreightClass = (item) => {
		const cubicInchesToCubicFeet = 0.0005787
		const volume = item.length * item.width * item.height
		const volumeInCubicFeet = volume * item.quantity * cubicInchesToCubicFeet

		// If volume or weight are 0, null, or undefined return 0
		if (!volumeInCubicFeet || !item.weight) return 0
		const density = item.weight / volumeInCubicFeet
		if (density >= 30) return 60
		if (density >= 22.5) return 65
		if (density >= 15) return 70
		if (density >= 12) return 85
		if (density >= 10) return 92.5
		if (density >= 8) return 100
		if (density >= 6) return 125
		if (density >= 4) return 175
		if (density >= 2) return 250
		if (density >= 1) return 300
		if (density > 0) return 400
		return 0
	}
	const onNextStep = async () => {
		if (Object.keys(reQuoteDetails).length !== 0) {
			const currentDate = moment(new Date()).format('YYYY-MM-DD')
			const pickUpDateReQuote = moment(pickUpDate).format('YYYY-MM-DD')
			if (pickUpDateReQuote < currentDate) {
				sweetAlertModal('warning', 'Please select a valid pickup date!', null, 'Ok', true, false)
				setDisableNextButton(false)
				return
			}
		}

		if (
			originData === null ||
			Object.keys(originData).length === 0 ||
			destinationData === null ||
			Object.keys(destinationData).length === 0
		) {
			sweetAlertModal('warning', 'Origin and Destination are required!', null, 'Ok', true, false)
			setDisableNextButton(false)
			return
		}

		if (
			(!itemsRef.current || itemsRef.current.length === 0) &&
			!itemInputsRef.current.find((element) => element !== null)
		) {
			sweetAlertModal('warning', 'There must be at least one item added!', null, 'Ok', true, false)
			setDisableNextButton(false)
			return
		}

		if (linearFeet === '') {
			sweetAlertModal('warning', 'Add or calculate the total linear feet', null, 'Ok', true, false)
			setDisableNextButton(false)
			return
		}
		// eslint-disable-next-line no-restricted-globals
		if (isNaN(+linearFeet) || +linearFeet <= 0) {
			sweetAlertModal('warning', 'Invalid linear feet value', null, 'Ok', true, false)
			setDisableNextButton(false)
			return
		}
		setLinearFeet(`${+linearFeet}`)

		let accessorialsSelected = []

		let calculatedWeight = 0
		const shipmentItemsAdded = itemsRef.current?.map((item) => {
			calculatedWeight += Number(item.weight)
			const nmfcSubstr = item.nmfc ? item.nmfc.substring(0, item.nmfc.indexOf('-')) : null
			const nmfc = item.nmfc ? item.nmfc : null
			const shipItems = {
				weight: {
					unit: 'LBS',
					value: item.weight,
				},
				unitType: item.packageType,
				pieces: {
					unit: item.packageType,
					value: item.quantity,
				},
				class: item.freightClass === 'Not sure' ? getFreightClass(item).toString() : item.freightClass,
				nmfc: !nmfcSubstr ? nmfc : nmfcSubstr,
				subNmfc: nmfcSubstr ? item.nmfc.substring(item.nmfc.indexOf('-') + 1) : null,
				isHazMat: item.hazardous,
				stackable: item.stackable,
				hazMatData:
					item.hazardous && item.hazmatData && Object.keys(item.hazmatData).length !== 0
						? {
								shippingName: item.hazmatData.shippingName,
								hazMatNumberType: item.hazmatData.hazMatNumberType,
								hazMatNumber: item.hazmatData.hazMatNumber,
								hazMatClass: item.hazmatData.hazMatClass,
								hazMatGroup: item.hazmatData.hazMatGroup,
								contactInformation: {
									name: item.hazmatData.name,
									phone: item.hazmatData.phone.replace(/[() -]/g, ''),
									zipcode: null,
									state: null,
									country: null,
								},
							}
						: null,
				dimensions: {
					unit: 'IN',
					lenght: item.length,
					width: item.width,
					height: item.height,
				},
				description: item.commodity,
			}
			return shipItems
		})

		setTotalWeight(calculatedWeight)

		/* eslint no-param-reassign: ["error", { "props": false }] */
		const originAccessorialsPayload =
			originAccessorialsSelected.length > 0
				? originAccessorialsSelected.map((accessorial) => ({
						accessorialId: accessorial.accessorialId,
						internalCode: accessorial.internalCode,
						name: accessorial.name,
						origin_destination: accessorial.origin_destination,
						value: accessorial.name,
					}))
				: []

		const destinationAccessorialsPayload =
			destinationAccessorialsSelected.length > 0
				? destinationAccessorialsSelected.map((accessorial) => ({
						accessorialId: accessorial.accessorialId,
						internalCode: accessorial.internalCode,
						name: accessorial.name,
						origin_destination: accessorial.origin_destination,
						value: accessorial.name,
					}))
				: []

		const overlengthFound = originAccessorialsPayload.find(
			(accessorial) =>
				accessorial.name === 'OVERLENGTH CHARGE 08.00 FEET' ||
				accessorial.name === 'OVERLENGTH CHARGE 12.00 FEET' ||
				accessorial.name === 'OVERLENGTH CHARGE 20.00 FEET' ||
				accessorial.name === 'OVERLENGTH CHARGE 28.00 FEET',
		)
		if (overlengthFound) {
			overlengthFound.value = overlengthValue
		}

		const CODFound = destinationAccessorialsPayload.find((accessorial) => accessorial.name === 'CASH ON DELIVERY')
		if (CODFound) {
			CODFound.value = CODValue
		}

		accessorialsSelected = [...(originAccessorialsPayload || []), ...(destinationAccessorialsPayload || [])]

		const originAccs = originAccessorialsPayload.map((accsObj) => accsObj.name)
		const destinationAccs = destinationAccessorialsPayload.map((accsObj) => accsObj.name)
		const ishazmat = shipmentItemsAdded.findIndex((item) => item?.isHazMat ?? false)
		if (ishazmat !== -1) {
			if (!originAccs.includes('HAZARDOUS MATERIAL')) {
				originAccs.push('HAZARDOUS MATERIAL')
			}
		}
		setAccessorialsOriginName(originAccs)
		setAccessorialsDestinationName(destinationAccs)

		// Set route info with the coords and quote information.
		setMarkersData()

		const getPickupDate = () => {
			const FormatedCurrentDate = moment(new Date()).format('YYYY-MM-DD')
			const FormatedPickUpDate = moment(pickUpDate).format('YYYY-MM-DD')
			let newPickupDate
			if (FormatedPickUpDate === FormatedCurrentDate) {
				newPickupDate = pickUpDate
			} else {
				newPickupDate = moment(pickUpDate).format('YYYY-MM-DDTh:mm:ss')
			}
			return newPickupDate
		}

		const payload = {
			shipper: {
				locationId: originData.id || null,
				companyName: originData.name || null,
				address: originData.address || null,
				address2: originData.address2 || null,
				contact: originData.contact,
				phone: originData.phone,
				phoneExtension: originData.phoneExtension,
				email: originData.email || null,
				zipcode: originData.zip_code || originData.postalCode,
				city: originData.city,
				state: originData.state,
				country: originData.country,
				pickupDate: getPickupDate(),
				latitude: originData.latitude,
				longitude: originData.longitude,
			},
			consignee: {
				locationId: destinationData.id || null,
				companyName: destinationData.name || null,
				address: destinationData.address,
				address2: destinationData.address2 || null,
				contact: destinationData.contact,
				phone: destinationData.phone,
				email: destinationData.email || null,
				zipcode: destinationData.zip_code || destinationData.postalCode,
				city: destinationData.city,
				state: destinationData.state,
				country: destinationData.country,
				dropOffDate: getPickupDate(),
				latitude: destinationData.latitude,
				longitude: destinationData.longitude,
			},
			type: 'LTL',
			items: shipmentItemsAdded,
			accessorials: accessorialsSelected,
			references: referencesRef.current,
			linearFeet: +linearFeet ?? null,
		}
		dispatch(setTabIsNewQuote(false))
		setPreviousItems(itemsRef.current)
		if (isEdit) {
			const { status, resp } = await dispatch(editQuote(quoteCreatedId, payload))
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error in the create quote process!', resp, 'Ok', true, false)
			} else {
				dispatch(setTabStep(2))
				setActiveStep2()
				const { statusRates } = await dispatch(fetchQuoteRates(`/${quoteCreatedId}`))
				if (statusRates === 'error') {
					sweetAlertModal('error', 'There was an error in the get rates process!', null, 'Ok', true, false)
				}
			}
		} else {
			const { status, resp } = await dispatch(createQuote(payload))
			if (status === 'error') {
				sweetAlertModal('error', 'There was an error in the create quote process!', resp, 'Ok', true, false)
			} else {
				dispatch(setTabQuoteId(resp.quoteId))
				dispatch(setTabStep(2))
				setQuoteCreatedId(resp.quoteId)
				dispatch(setTabStatus('ACTIVE'))
				setActiveStep2()
				dispatch(clearDetailsCopyForQuote())
				const { statusRates, respRates } = await dispatch(fetchQuoteRates(`/${resp.quoteId}`))
				if (statusRates === 'error') {
					sweetAlertModal('error', 'There was an error in the get rates process!', null, 'Ok', true, false)
				} else {
					dispatch(setTabRates(respRates))
				}
			}
		}
		setDisableNextButton(false)
	}

	const validateReferenceForms = async () => {
		if (referenceInputsRef.current) {
			const refs = referenceInputsRef.current.filter((ref) => ref !== null)

			// Map over refs to create an array of promises
			const validationPromises = refs.map(async (ref) => {
				const response = await ref.submitForm()
				return response !== 'error'
			})

			// Wait for all validations to complete
			const validationResults = await Promise.all(validationPromises)

			const allValid = validationResults.every(Boolean)

			if (!allValid) {
				setDisableNextButton(false)
				return
			}

			await onNextStep()
		}
	}

	/*
    This function was done this way to connect previous V1 implementation of Items list form,
    with the refactor done in March 2022
  */
	const validateItemForms = async (isNextStep) => {
		if (isNextStep) setDisableNextButton(true)

		if (itemInputsRef.current) {
			// Filter out null references
			const refs = itemInputsRef.current.filter((ref) => ref !== null)

			// Map over refs to create an array of promises
			const validationPromises = refs.map(async (ref) => {
				// Validate the inputs in the row
				const responses = await Promise.all(ref.validateRowInputs())
				const allValid = responses.every(Boolean)

				// Get the values of the row
				const rowValues = await ref.getRowValues()

				return { allValid, rowValues }
			})

			// Wait for all validations to complete
			const validationResults = await Promise.all(validationPromises)

			// Extract results
			const itemsResponse = validationResults.map((result) => result.allValid)
			const shipmentItemsRows = validationResults.map((result) => result.rowValues)

			setShipmentItems(shipmentItemsRows)

			const canGoNextStep = itemsResponse.every(Boolean)

			if (!canGoNextStep) {
				if (isNextStep) setDisableNextButton(false)
				return
			}

			if (isNextStep) {
				await validateReferenceForms()
			}
		}
	}

	const handleSaveQuote = async () => {
		let params = ''
		if (!isReQuote) {
			params = `?quoteId=${quoteCreatedId}&rateId=${selectedRateId}`
		} else {
			params = `?quoteId=${quoteCreatedId}&rateId=${selectedRateId}&requote=true`
		}
		const { statusSelectRate, resp } = await dispatch(selectRate(params, { insurance: insurancePayload }))
		if (statusSelectRate === 'error') {
			sweetAlertModal('error', 'There was an error in the select rate process!', resp, 'Ok', true, false)
		} else {
			const { statusExpiration } = await dispatch(validateQuoteExpiration(quoteCreatedId, selectedRateId))
			if (statusExpiration === 'error') {
				sweetAlertModal(statusExpiration, 'This quote has expired, please re-quote!', null, 'Ok', true, false, null)
			} else {
				setQuoteSavedConfirm(true)
			}
		}
	}

	const onNextStepThree = useCallback(
		async (_, confirmedInsurance = false) => {
			if (!confirmedInsurance) {
				setOpenConfirmInsurance(true)
				return
			}

			let params = ''
			if (selectedRateId === 0) return
			if (!isReQuote) {
				params = `?quoteId=${quoteCreatedId}&rateId=${selectedRateId}`
			} else {
				params = `?quoteId=${quoteCreatedId}&rateId=${selectedRateId}&requote=true`
			}

			const { statusSelectRate } = await dispatch(selectRate(params, { insurance: insurancePayload }))
			if (statusSelectRate === 'error') {
				sweetAlertModal('error', 'There was an error in the select rate process!', null, 'Ok', true, false)
				return
			}
			const { statusExpiration, resp } = await dispatch(validateQuoteExpiration(quoteCreatedId, selectedRateId))
			if (statusExpiration === 'error') {
				if (resp === expiredError) {
					sweetAlertModal(statusExpiration, 'This quote has expired, please requote!', null, 'Ok', true, false, null)
				}
				if (resp === convertedShipmentError) {
					sweetAlertModal(
						statusExpiration,
						`Quote with ID: ${quoteCreatedId} already has a shipment`,
						null,
						'Ok',
						true,
						false,
						null,
					)
				}
				return
			}
			const statusUpdateQuote = await actionUpdateDeliveryDay(dispatch, quoteRates, selectedRateId, quoteCreatedId)
			const { status } = await dispatch(fetchQuoteDetails(`/${quoteCreatedId}`, true))
			if (status === 'error' || statusUpdateQuote.status === 'error') {
				sweetAlertModal(status, 'There was an error in the convert to shipment process!', null, 'Ok', true, false, null)
			} else {
				dispatch(setTabStep(3))
				history.push(createShipmentsRoute)
			}
		},
		[insurancePayload, dispatch, history, isReQuote, quoteCreatedId, quoteRates, selectedRateId],
	)

	const onPrevStep = () => {
		setSelectedRateId(0)
		setIsEdit(true)
		setIsReQuote(false)
		setDisableNextButton(false)
		setActiveStep1()
		dispatch(setTabStep(1))
		dispatch(clearRates())
		window.location.href = '#'
	}

	const handleCancelQuoteClick = () => {
		setIsCancellingQuote(true)
		if (Object.keys(reQuoteDetails).length !== 0) {
			sweetAlertModal('warning', 'Are you sure you want to cancel the re-quote?', null, 'Yes', true, true, 'No').then(
				(result) => {
					if (result.isConfirmed) {
						history.push(quotesListRoute)
					}
				},
			)
		} else {
			sweetAlertModal('warning', 'Are you sure you want to cancel the quote?', null, 'Yes', true, true, 'No').then(
				(result) => {
					if (result.isConfirmed) {
						history.push(quotesListRoute)
					}
				},
			)
		}
	}

	const dispatchSetOrigin = (origin) => {
		dispatch(setTabOrigin(origin))
	}

	const dispatchSetDestination = (destination) => {
		dispatch(setTabDestination(destination))
	}

	const dispatchSetOriginAccessorials = (accessorials) => {
		dispatch(setTabOriginAccessorials(accessorials))
	}

	const dispatchSetDestinationAccessorials = (accessorials) => {
		dispatch(setTabDestinationAccessorials(accessorials))
	}

	const dispatchSetReferences = (reference) => {
		dispatch(setTabReferences(reference))
	}

	const dispatchSetShipmentItemsStore = (item) => {
		dispatch(setTabShipmentItems(item))
	}

	const dispatchSetOriginData = (originDataSelected) => {
		dispatch(setTabOriginData(originDataSelected))
	}

	const dispatchSetDestinationData = (destinationDataSelected) => {
		dispatch(setTabDestinationData(destinationDataSelected))
	}

	useImperativeHandle(refGlobal, () => ({
		onSubmitStep2() {
			if (selectedRateId !== 0) {
				onNextStepThree()
			} else {
				sweetAlertModal('info', null, 'Please, select a carrier', 'Ok', true, false)
			}
		},
		onBackStepGoTo1() {
			onPrevStep()
		},
	}))

	return (
		<>
			{[2].includes(activeStep) && (
				<Prompt
					when
					message={(locationRoute) =>
						locationRoute.pathname !== createShipmentsRoute &&
						locationRoute.pathname !== createQuotesRoute &&
						!openQuoteSavedConfirm &&
						!isCancellingQuote
							? 'Are you sure you want to leave this page? The rate results will be canceled and the quote will be saved as Pending'
							: true
					}
				/>
			)}
			{[1, 2].includes(activeStep) && (
				<>
					{activeStep === 1 && (
						<NewQuoteShipmentStepOne
							originData={originData}
							setOriginData={setOriginData}
							dispatchSetOrigin={dispatchSetOrigin}
							dispatchSetDestination={dispatchSetDestination}
							setDestinationData={setDestinationData}
							hereMapsResponseOrigin={hereMapsResponseOrigin}
							hereMapsResponseDestination={hereMapsResponseDestination}
							valueOrigin={valueOrigin}
							setValueOrigin={setValueOrigin}
							valueDestination={valueDestination}
							setValueDestination={setValueDestination}
							setHereMapsResponseOrigin={setHereMapsResponseOrigin}
							setHereMapsResponseDestination={setHereMapsResponseDestination}
							pickUpDate={pickUpDate}
							setPickUpDate={setPickUpDate}
							siteTypeOrigin={siteTypeOrigin}
							setSiteTypeOrigin={setSiteTypeOrigin}
							siteTypeDestination={siteTypeDestination}
							setSiteTypeDestination={setSiteTypeDestination}
							valueOriginAccs={valueOriginAccs}
							setValueOriginAccs={setValueOriginAccs}
							originAccessorialsSelected={originAccessorialsSelected}
							setOriginAccessorialsSelected={setOriginAccessorialsSelected}
							valueDestinationAccs={valueDestinationAccs}
							setValueDestinationAccs={setValueDestinationAccs}
							destinationAccessorialsSelected={destinationAccessorialsSelected}
							setDestinationAccessorialsSelected={setDestinationAccessorialsSelected}
							overlengthValue={overlengthValue}
							setOverlengthValue={setOverlengthValue}
							CODValue={CODValue}
							setCODValue={setCODValue}
							setReferencesAdded={setReferencesAdded}
							items={previousItems}
							isEdit={isEdit}
							isReQuote={isReQuote}
							previousReferences={previousReferences}
							setPreviousReferences={setPreviousReferences}
							setInputValueOrigin={setInputValueOrigin}
							inputValueOrigin={inputValueOrigin}
							inputValueDestination={inputValueDestination}
							setInputValueDestination={setInputValueDestination}
							dispatchSetOriginAccessorials={dispatchSetOriginAccessorials}
							dispatchSetDestinationAccessorials={dispatchSetDestinationAccessorials}
							dispatchSetReferences={dispatchSetReferences}
							dispatchSetShipmentItemsStore={dispatchSetShipmentItemsStore}
							dispatchSetOriginData={dispatchSetOriginData}
							dispatchSetDestinationData={dispatchSetDestinationData}
							ref={itemInputsRef}
							referenceInputsRef={referenceInputsRef}
							freightClassRef={freightClassRef}
							linearFeet={linearFeet}
							setLinearFeet={setLinearFeet}
							refInfoItemsValues={infoItemsValues}
						/>
					)}
					{activeStep === 2 && (
						<NewQuoteShipmentStepTwo
							originData={originData}
							destinationData={destinationData}
							markers={markers}
							quoteCreatedId={quoteCreatedId}
							accessorialsOrigin={accessorialsOriginName}
							accessorialsDestination={accessorialsDestinationName}
							totalWeight={totalWeight}
							creationDate={creationDate}
							shipmentItems={shipmentItems}
							references={referencesAdded}
							siteTypeOrigin={siteTypeOrigin}
							siteTypeDestination={siteTypeDestination}
							pickUpDate={pickUpDate}
							selectedRateId={selectedRateId}
							setSelectedRateId={setSelectedRateId}
							openQuoteSavedConfirm={openQuoteSavedConfirm}
							setQuoteSavedConfirm={setQuoteSavedConfirm}
							onNextStepThree={onNextStepThree}
							linearFeet={linearFeet}
							openConfirmInsurance={openConfirmInsurance}
							setOpenConfirmInsurance={setOpenConfirmInsurance}
							enableInsuranceOptIn={enableInsuranceOptIn}
							defaultDeclaredValue={defaultDeclaredValue}
							declaredFreightValue={declaredFreightValue}
							setDeclaredFreightValue={setDeclaredFreightValue}
							withInsurance={withInsurance}
							setWithInsurance={setWithInsurance}
							setInsuranceCost={setInsuranceCost}
							setInsuranceValue={setInsuranceValue}
						/>
					)}
					<Grid container className={classes.buttonsRow}>
						{activeStep !== 2 && (
							<Button className={classes.button} onClick={() => handleCancelQuoteClick()}>
								Cancel Quote
							</Button>
						)}
						{activeStep === 1 ? (
							<Button
								disabled={disableNextButton}
								className={classes.nextStepButton}
								onClick={() => validateItemForms(true)}
							>
								Next Step
							</Button>
						) : (
							<Button className={classes.previousButton} variant="contained" onClick={onPrevStep}>
								Previous
							</Button>
						)}
						{activeStep === 2 && (
							<Button
								className={classes.button}
								style={{ margin: matches ? '0 20px' : '0' }}
								onClick={() => handleCancelQuoteClick()}
							>
								Cancel Quote
							</Button>
						)}
						{activeStep === 2 && !quoteRates.isLoading ? <DownloadRates quoteCreatedId={quoteCreatedId} /> : null}
						{activeStep === 2 && (
							<>
								{isPermit(permissions, permissionName.QUOTES_CONVERT_TO_SHIPMENT) && (
									<Button
										className={classes.shipmentButton}
										style={{ marginLeft: quoteRates.isLoading || !matches ? '0' : '1em' }}
										variant="contained"
										color="secondary"
										onClick={onNextStepThree}
										disabled={selectedRateId === 0}
									>
										Convert to Shipment
									</Button>
								)}
								<Button
									className={classes.saveQuoteButton}
									onClick={handleSaveQuote}
									disabled={selectedRateId === 0}
									color="primary"
									startIcon={<SaveIcon color="secondary" />}
								>
									Save Quote
								</Button>
							</>
						)}
					</Grid>
				</>
			)}
		</>
	)
})

NewQuoteStepsContainer.propTypes = {
	activeStep: PropTypes.number.isRequired,
	setActiveStep1: PropTypes.func.isRequired,
	setActiveStep2: PropTypes.func.isRequired,
}

export default NewQuoteStepsContainer
